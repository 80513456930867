.notification {
  position: sticky;
  top: 0;
  z-index: 1201;

  .drawer-arrow-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background-color: var(--lighterBg);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1202;

    &.admin-wrapper {
      margin-bottom: 0;
    }

    .drawer-arrow-contain {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;

      .menu-arrow {
        z-index: 1201;
      }

      .drawer-left {
        display: flex;
        align-items: center;
        gap: var(--gap);

        img {
          max-width: 144px;
          width: 100%;
        }

        @media (max-width: 500px) {
          img {
            max-width: 80px;
          }
        }

        @media (max-width: 420px) {
          img {
            display: none;
          }
        }
      }

      .drawer-right {
        display: flex;

        .drawer-arrow {
          position: relative;
          z-index: 1201;
          width: 40px;
          height: 40px;
        }

        .drawer-settings {
          position: relative;
          z-index: 1201;
          min-width: 40px;
          width: 40px;
          height: 40px;
          color: rgba(0, 0, 0, 0.54);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }

  .notification-drawer {
    .MuiDrawer-paper {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 0;

      .notifications {
        margin-top: 64px;
        display: grid;

        .notification {
          height: 90px;
          background-color: var(--notRed);
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          position: relative;

          &:hover {
            background-color: var(--notRed);
          }

          .notification-progress {
            width: 2px;
            height: 100%;
            background-color: #00000033;
            position: absolute;
            margin-left: 20px;
          }

          .notification-status {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 11px;
            top: 13px;
            width: 20px;
            height: 20px;
            border: 2px solid #00000033;
            background-color: var(--notRed);
            border-radius: 50%;

            .status-date {
              font-size: 12px;
              color: var(--lighterBg);
              position: absolute;
              margin-left: 73px;
              font-weight: 700;
              padding-bottom: 1px;
            }

            svg {
              color: #00000033;
              height: 14px;
            }
          }

          .notification-text {
            color: var(--lighterBg);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            margin-left: 40px;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 100%;
            border: none;
            outline: none;
            cursor: pointer;
            background: transparent;
            transition: all 150ms linear;

            &:hover {
              background: #00000033;
              transition: all 150ms linear;
            }

            svg {
              color: rgb(0 0 0 / 40%);
            }
          }
        }
      }
    }
  }
}
