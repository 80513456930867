.support-tazmin-modal {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
}
.support-tazmin-modal .tazmin-triple {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap2x);
}
.support-tazmin-modal .tazmin-triple.disabled {
  opacity: 0.7;
}
.support-tazmin-modal .tazmin-headline {
  display: flex;
  align-items: center;
  gap: var(--gap2x);
  flex-wrap: wrap;
}
.support-tazmin-modal .tazmin-headline h5 {
  color: var(--priceText);
}
.support-tazmin-modal .tazmin-navlun {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap3x);
}
.support-tazmin-modal .tazmin-navlun h5 {
  color: var(--priceText);
}
.support-tazmin-modal .tazmin-navlun .tazmin-triple {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap2x);
}
.support-tazmin-modal .tazmin-navlun .tazmin-triple.disabled {
  opacity: 0.7;
}/*# sourceMappingURL=SupportTazmin.css.map */