.members {
  background-color: var(--background);
  min-height: 100vh;

  .members-container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;

    .member-list {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);

      .member-list-row {
        border-radius: var(--wrapperBorderRadius);
        padding: var(--gap);
        box-shadow: var(--shadow);
        transition: all 150ms linear;
        display: flex;
        gap: var(--gap);

        &:hover {
          box-shadow: var(--blueShadow);
          transition: all 150ms linear;
        }

        .member-list-column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          gap: 5px;
          font-size: 12px;

          .member-list-column-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            max-width: 270px;
            width: 100%;
            margin: 0 auto;
          }

          &.price {
            max-width: 220px;
            font-size: 12px;
          }

          &.buttons {
            max-width: 200px;
            font-size: 12px;
          }

          .member-list-column-text {
            color: var(--priceText);
            font-size: 12px;

            * {
              font-size: inherit;
            }
          }

          .member-list-column-active-button {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 6px 8px;
            background-color: var(--shipmentGreen);
            color: var(--lighterBg);
            border-radius: var(--wrapperBorderRadius);
          }

          .list-column {
            color: var(--lighterBg);
            padding: 6px 8px;
            border-radius: var(--wrapperBorderRadius);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: var(--gap);
            text-transform: none;
            font-size: 13px;

            &.active {
              background-color: var(--shipmentGreen);
              transition: all 150ms linear;
            }

            &.passive {
              background-color: var(--shipmentRed);
              transition: all 150ms linear;
            }

            &.hold {
              background-color: var(--info);
              transition: all 150ms linear;
              user-select: none;
            }

            &.check {
              padding: 0;
              gap: 0;
              color: var(--priceText);
            }
          }
        }
      }

      .member-search {
        background-color: var(--lighterBg);
        padding: 20px;
        border-radius: var(--wrapperBorderRadius);
        box-shadow: var(--shadow);

        .search-card {
          .search-card-content {
            display: flex;
            gap: var(--gap2x);
          }
        }

        .member-type {
          display: flex;
          gap: var(--gap2x);

          .member-type-item {
            display: flex;
            gap: var(--gap);
            padding: 5px;
            border-radius: var(--wrapperBorderRadius);
            cursor: pointer;
            align-items: center;

            &:hover {
              box-shadow: var(--shadow);
            }

            &.selected-member-type {
              box-shadow: var(--shadow);
            }

            .member-type-item-child {
              width: 21px;
              height: 21px;
              border-radius: 4px;

              &.individual-type {
                background-color: var(--pastelBlue);
              }

              &.company-type {
                background-color: var(--pastelRed);
              }

              &.all-type {
                background-color: var(--background);
              }
            }
          }
        }
      }
    }
  }
}
