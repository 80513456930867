.members {
  background-color: var(--background);
  min-height: 100vh;
}
.members .members-container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}
.members .members-container .member-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.members .members-container .member-list .member-list-row {
  border-radius: var(--wrapperBorderRadius);
  padding: var(--gap);
  box-shadow: var(--shadow);
  transition: all 150ms linear;
  display: flex;
  gap: var(--gap);
}
.members .members-container .member-list .member-list-row:hover {
  box-shadow: var(--blueShadow);
  transition: all 150ms linear;
}
.members .members-container .member-list .member-list-row .member-list-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 5px;
  font-size: 12px;
}
.members .members-container .member-list .member-list-row .member-list-column .member-list-column-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 270px;
  width: 100%;
  margin: 0 auto;
}
.members .members-container .member-list .member-list-row .member-list-column.price {
  max-width: 220px;
  font-size: 12px;
}
.members .members-container .member-list .member-list-row .member-list-column.buttons {
  max-width: 200px;
  font-size: 12px;
}
.members .members-container .member-list .member-list-row .member-list-column .member-list-column-text {
  color: var(--priceText);
  font-size: 12px;
}
.members .members-container .member-list .member-list-row .member-list-column .member-list-column-text * {
  font-size: inherit;
}
.members .members-container .member-list .member-list-row .member-list-column .member-list-column-active-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  background-color: var(--shipmentGreen);
  color: var(--lighterBg);
  border-radius: var(--wrapperBorderRadius);
}
.members .members-container .member-list .member-list-row .member-list-column .list-column {
  color: var(--lighterBg);
  padding: 6px 8px;
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap);
  text-transform: none;
  font-size: 13px;
}
.members .members-container .member-list .member-list-row .member-list-column .list-column.active {
  background-color: var(--shipmentGreen);
  transition: all 150ms linear;
}
.members .members-container .member-list .member-list-row .member-list-column .list-column.passive {
  background-color: var(--shipmentRed);
  transition: all 150ms linear;
}
.members .members-container .member-list .member-list-row .member-list-column .list-column.hold {
  background-color: var(--info);
  transition: all 150ms linear;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.members .members-container .member-list .member-list-row .member-list-column .list-column.check {
  padding: 0;
  gap: 0;
  color: var(--priceText);
}
.members .members-container .member-list .member-search {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}
.members .members-container .member-list .member-search .search-card .search-card-content {
  display: flex;
  gap: var(--gap2x);
}
.members .members-container .member-list .member-search .member-type {
  display: flex;
  gap: var(--gap2x);
}
.members .members-container .member-list .member-search .member-type .member-type-item {
  display: flex;
  gap: var(--gap);
  padding: 5px;
  border-radius: var(--wrapperBorderRadius);
  cursor: pointer;
  align-items: center;
}
.members .members-container .member-list .member-search .member-type .member-type-item:hover {
  box-shadow: var(--shadow);
}
.members .members-container .member-list .member-search .member-type .member-type-item.selected-member-type {
  box-shadow: var(--shadow);
}
.members .members-container .member-list .member-search .member-type .member-type-item .member-type-item-child {
  width: 21px;
  height: 21px;
  border-radius: 4px;
}
.members .members-container .member-list .member-search .member-type .member-type-item .member-type-item-child.individual-type {
  background-color: var(--pastelBlue);
}
.members .members-container .member-list .member-search .member-type .member-type-item .member-type-item-child.company-type {
  background-color: var(--pastelRed);
}
.members .members-container .member-list .member-search .member-type .member-type-item .member-type-item-child.all-type {
  background-color: var(--background);
}/*# sourceMappingURL=Members.css.map */