.proforma-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);
}
.proforma-details .details-columns {
  display: grid;
  width: 100%;
  gap: var(--gap2x);
}
.proforma-details .details-columns .details-column {
  display: flex;
  align-items: center;
}
.proforma-details .details-columns .details-column .column {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: var(--gap);
}
.proforma-details .details-columns .details-column .display {
  margin-left: 10px;
  color: var(--notRed);
  cursor: pointer;
  transition: all 150ms linear;
}
.proforma-details .details-columns .details-column .display:hover {
  color: var(--error);
  transition: all 150ms linear;
}
.proforma-details .details-columns .details-column .displayHide {
  display: none;
}
.proforma-details .proforma-add {
  color: var(--info);
  cursor: pointer;
}/*# sourceMappingURL=ProformaUpdate.css.map */