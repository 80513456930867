.announcement {
  min-height: 100vh;
  background-color: var(--background);

  .announcement-wrapper {
    display: grid;
    width: 100%;
    gap: var(--gap2x);

    .announcement-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--gap2x);

      h6 {
        color: var(--priceText);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
      }

      .announcement-add-wrapper {
        background-color: var(--lighterBg);
        border-radius: var(--inputBorderRadius);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: var(--gap2x);
        max-width: 1100px;
        width: 100%;
        margin: auto;

        .announcement-add-inputs {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: var(--gap2x);
        }

        .announcement-add-buttons {
          display: flex;
          justify-content: flex-end;
          gap: var(--gap2x);

          button {
            background-color: var(--info);
            color: var(--lighterBg);
            padding: 5px 15px;
            text-transform: none;
            font-size: 13px;
            transition: all 150ms linear;

            &:hover {
              background-color: var(--blueBrand);
              transition: all 150ms linear;
            }
          }
        }
      }

      .announcement-add {
        text-transform: none;
        background-color: var(--info);
        color: var(--lighterBg);
        transition: all 150ms linear;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        gap: 5px;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background-color: var(--blueBrand);
          transition: all 150ms linear;
        }
      }

      .announcement-list {
        background-color: var(--lighterBg);
        border-radius: var(--inputBorderRadius);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

        .announcement-list-table-container {
          .announcement-list-table {
            .announcement-list-table-body {
              .announcement-list-table-body-edit {
                background-color: var(--pastelYellow);
                color: var(--orangeBrand);
                padding: 5px;
                font-size: 13px;
                text-transform: none;
                border-radius: var(--inputBorderRadius);
                transition: all 150ms linear;

                &:hover {
                  background-color: var(--orangeBrand);
                  color: var(--pastelYellow);
                  transition: all 150ms linear;
                }
              }

              .announcement-list-table-body-delete {
                background-color: var(--pastelRed);
                color: var(--redBrand);
                padding: 5px;
                font-size: 13px;
                text-transform: none;
                border-radius: var(--inputBorderRadius);
                transition: all 150ms linear;

                &:hover {
                  background-color: var(--redBrand);
                  color: var(--pastelRed);
                  transition: all 150ms linear;
                }
              }
            }
          }
        }
      }
    }
  }
}