.operation {
  background-color: var(--background);
  min-height: 100vh;
}
.operation .operation-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
.operation .operation-wrapper .operation-container {
  display: grid;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk {
  background-color: var(--lighterBg);
  padding: 20px;
  box-shadow: var(--shadow);
  border-radius: var(--wrapperBorderRadius);
  display: grid;
  gap: var(--gap2x);
  max-width: 1100px;
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-freight-front-desk-checkbox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-feight-front-desk-dom {
  display: grid;
  gap: var(--gap2x);
  max-width: 1100px;
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-feight-front-desk-dom .operation-feight-front-desk-dom-input {
  display: flex;
  justify-content: space-between;
  gap: var(--gap2x);
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-feight-front-desk-dom .bottom-field {
  gap: 25px !important;
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-feight-front-desk-dom .operation-manuel-cargo {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-feight-front-desk-dom .operation-manuel-cargo .operation-manuel-cargo-item {
  display: flex;
  justify-content: space-between;
  gap: var(--gap2x);
}
.operation .operation-wrapper .operation-container .operation-freight-front-desk .operation-freight-front-desk-bee-status {
  padding: 20px;
  color: white;
  border-radius: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container {
  display: flex;
  gap: var(--gap2x);
  justify-content: space-between;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-info p {
  color: var(--priceText);
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-info p strong {
  color: var(--priceText);
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-info .resp-info-dimension {
  display: flex;
  gap: var(--gap3x);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-customer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-customer .resp-customer-box {
  border: 1px solid var(--inputBorderColor);
  padding: 7px 20px;
  border-radius: var(--wrapperBorderRadius);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-customer .resp-customer-info {
  color: var(--priceText);
  font-size: 12px;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-customer .resp-customer-price {
  display: flex;
  justify-content: space-around;
  gap: var(--gap2x);
  color: var(--priceText);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper {
  background-color: var(--priceText);
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container {
  display: flex;
  background-color: var(--lighterBg);
  border-radius: var(--wrapperBorderRadius);
  position: relative;
  height: 140px;
  width: 280px;
  padding: 20px;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-number {
  position: absolute;
  top: 9px;
  right: 55px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-page {
  position: absolute;
  right: 20px;
  top: 5px;
  font-weight: 700;
  font-size: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-barcode {
  display: grid;
  justify-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-barcode img {
  height: 50px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-divider {
  width: 1px;
  height: 100%;
  background: var(--darkerTextColor);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-saler {
  margin-bottom: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-saler p {
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-customer p {
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp .operation-cargo-dom-resp-container .resp-card .resp-card-buttons {
  display: flex;
  justify-content: space-around;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets-gruop {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  max-width: 1100px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets-gruop .operation-cargo-dom-resp-tickets {
  display: flex;
  gap: var(--gap);
  margin-bottom: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets-gruop .operation-cargo-dom-resp-tickets .resp-ticket {
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--wrapperBorderRadius);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets-gruop .operation-cargo-dom-resp-tickets .resp-ticket p {
  color: var(--lighterBg);
  font-size: 28px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets-gruop .operation-cargo-dom-resp-tickets .resp-ticket svg {
  height: 175px;
  width: 175px;
  color: var(--lighterBg);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  max-width: 1100px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket {
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--wrapperBorderRadius);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket p {
  color: var(--lighterBg);
  font-size: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket.okay {
  max-width: 360px;
  gap: 18px;
  max-height: 160px;
  background-color: var(--shipmentGreen);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket.denied {
  background-color: var(--shipmentRed);
  max-width: 360px;
  max-height: 160px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket.denied p {
  margin-bottom: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-tickets .resp-ticket.problem {
  background-color: var(--shipmentYellow);
  max-width: 360px;
  max-height: 160px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info {
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top {
  display: flex;
  gap: 12px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-sender {
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 244px;
  height: 131px;
  color: #6a707e;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-sender .button-fix {
  width: 73px;
  height: 24px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-reciever {
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 244px;
  height: 131px;
  color: #6a707e;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-reciever .button-fix {
  width: 73px;
  height: 24px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-carrying {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 170px;
  height: 131px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-top .operation-cargo-dom-resp-info-carrying div {
  width: 40px;
  height: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 682px;
  height: 103px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-integration {
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-integration div {
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-integration div:first-child {
  color: orange;
  font-size: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-integration div:last-child {
  font-weight: 700;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-plane {
  display: grid;
  gap: 5px;
  text-align: center;
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-plane .plane-flag {
  display: flex;
  gap: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-plane .plane-flag #land {
  color: var(--shipmentGreen);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-plane .plane-flag #take-off {
  color: var(--error);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .steps-where-to .to-tracking {
  background-color: var(--background);
  padding: 5px 30px;
  border-radius: var(--buttonBorderRadius);
  font-size: 12px;
  color: var(--priceText);
  font-weight: 700;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-info .operation-cargo-dom-resp-info-bottom .to-integration img {
  width: 15px;
  height: 15px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf {
  width: 407px;
  height: 243px;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-number {
  position: absolute;
  top: 9px;
  right: 55px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-page {
  position: absolute;
  right: 20px;
  top: 5px;
  font-weight: 700;
  font-size: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-barcode {
  display: grid;
  justify-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-barcode img {
  height: 50px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-divider {
  width: 1px;
  height: 100%;
  background: var(--darkerTextColor);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-saler {
  margin-bottom: var(--gap);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-saler p {
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-general-info .operation-cargo-dom-resp-pdf .resp-card-wrapper .resp-card-container .resp-card-info .resp-card-customer p {
  font-size: 13px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-domestic-dimension {
  max-width: 1100px;
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-domestic-dimension .title {
  color: #8c8c8c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-domestic-dimension .operation-manuel-cargo-item {
  display: flex;
  gap: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight {
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment {
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .title {
  color: #6a707e;
  font-family: Inter;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-top {
  display: flex;
  gap: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-top .operation-cargo-dom-resp-wight-shipment-item {
  color: #6a707e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-top .operation-cargo-dom-resp-wight-shipment-item .little-title {
  font-size: 7px;
  font-weight: 400;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-top .operation-cargo-dom-resp-wight-shipment-item .operation-cargo-dom-resp-wight-shipment-item-bottom {
  background-color: #e9ecef;
  width: 50px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-bottom .operation-cargo-dom-resp-wight-shipment-bottom-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #6a707e;
  gap: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-bottom .operation-cargo-dom-resp-wight-shipment-bottom-side .little-title {
  font-size: 7px;
  font-weight: 400;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-wight-shipment .operation-cargo-dom-resp-wight-shipment-group .operation-cargo-dom-resp-wight-shipment-group-bottom .operation-cargo-dom-resp-wight-shipment-bottom-side .operation-cargo-dom-resp-wight-shipment-item-bottom {
  background-color: #e9ecef;
  width: 119px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-weight-iyziteam {
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  width: 540px;
  min-height: 208px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-weight-iyziteam .title {
  color: #6a707e;
  font-family: Inter;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-weight-iyziteam .operation-cargo-dom-resp-weight-iyziteam-dimension {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-weight-iyziteam .operation-cargo-dom-resp-weight-iyziteam-dimension .operation-cargo-dom-resp-weight-iyziteam-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #6a707e;
  gap: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-weight .operation-cargo-dom-resp-weight-iyziteam .operation-cargo-dom-resp-weight-iyziteam-dimension .operation-cargo-dom-resp-weight-iyziteam-item .little-title {
  font-size: 7px;
  font-weight: 400;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-pak {
  background-color: var(--lighterBg);
  padding: 10px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
  max-width: 1100px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-pak .operation-cargo-dom-resp-weight-iyziteam-pak {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-pak .operation-cargo-dom-resp-weight-iyziteam-pak .operation-cargo-dom-resp-weight-iyziteam-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #6a707e;
  gap: 5px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-pak .operation-cargo-dom-resp-weight-iyziteam-pak .operation-cargo-dom-resp-weight-iyziteam-item .little-title {
  font-size: 7px;
  font-weight: 400;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-proforma .title {
  color: #8c8c8c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  margin-bottom: 20px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-proforma .title img {
  margin-right: 15px;
  margin-left: 10px;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-proforma .operation-cargo-dom-resp-proforma-up {
  display: flex;
  justify-content: space-between;
}
.operation .operation-wrapper .operation-container .operation-cargo-dom-resp-wrapper .operation-cargo-dom-resp-proforma .operation-cargo-dom-resp-container .title {
  margin-top: 15px;
}
.operation .operation-wrapper .operation-container .operation-security-check {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--shadow);
}/*# sourceMappingURL=Operation.css.map */