.courier-delivered-admin {
  max-width: 1100px;
  width: 100%;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  @media screen and (max-width: 768px) {
    width: 90%;
  }

  .courier-delivered-drag-drop {
    display: grid;
    grid-auto-flow: column;
    gap: var(--gap2x);
    @media screen and (max-width: 768px) {
      grid-auto-flow: row;
    }

    .courier-items-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      .courier-items-headline {
        color: var(--priceText);
        font-size: 16px;
      }

      .courier-items-text {
        color: var(--priceText);
        font-size: 13px;

        .courier-items-text-code {
          color: var(--info);
          font-weight: 700;
          font-size: 13px;
        }
      }

      .courier-items {
        height: 100%;
        width: 360px;
        margin: auto;
        @media screen and (max-width: 468px) {
          width: 310px;
        }

        .courier-item-container {
          cursor: pointer;
          transition: all 150ms linear;
          @media screen and (max-width: 468px) {
            width: 90%;
          }

          &:first-child:hover {
            background-color: var(--pastelBlue);
            transition: all 150ms linear;
            border-top-left-radius: var(--inputBorderRadius);
            border-top-right-radius: var(--inputBorderRadius);
          }

          &:last-child:hover {
            background-color: var(--pastelBlue);
            transition: all 150ms linear;
            border-bottom-left-radius: var(--inputBorderRadius);
            border-bottom-right-radius: var(--inputBorderRadius);
          }

          &:hover {
            background-color: var(--pastelBlue);
            transition: all 150ms linear;
          }

          .courier-item {
            color: var(--priceText);

            .courier-item-awb {
              font-weight: 700;
              color: var(--darkerTextColor);
            }
          }
        }
      }
    }

    .courier-items-changer {
      display: flex;
      flex-direction: column;
      gap: var(--gap2x);
      align-self: center;
      margin-top: var(--gap6x);
      @media screen and (max-width: 468px) {
        width: 90%;
      }

      .items-changer {
        padding: 3px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--buttonBorderRadius);

        &.none {
          border: 1px solid var(--inputBorderColor);
        }

        &.has-items {
          border: 1px solid var(--info);
          cursor: pointer;
        }
      }
    }
  }

  .courier-delivered-input {
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    .delivered-input-headline {
      color: var(--priceText);
      font-size: 13px;

      .headline-span {
        font-weight: 700;
        color: var(--info);
        font-size: 13px;
      }
    }
  }

  .courier-delivered-button {
    display: flex;
    gap: var(--gap2x);
    justify-content: flex-end;

    .abort {
      background-color: var(--inputBorderColor);
      color: var(--lighterBg);
      border-radius: var(--inputBorderRadius);
      text-transform: none;
      max-width: 100px;
      width: 100%;
      transition: all 150ms linear;

      &:hover {
        transition: all 150ms linear;
        background-color: var(--priceText);
      }
    }

    .send {
      background-color: var(--info);
      color: var(--lighterBg);
      border-radius: var(--inputBorderRadius);
      text-transform: none;
      max-width: 100px;
      width: 100%;
      transition: all 150ms linear;

      &:hover {
        transition: all 150ms linear;
        background-color: var(--blueBrand);
      }
    }
  }
}
