.login {
  display: flex;
  background-color: var(--background);
  font-size: var(--smallTextSize);
}
.login .login-sign {
  width: 100%;
  min-height: 100vh;
  padding: 30px 70px;
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  max-width: 640px;
  margin: 0 auto;
}
.login .login-sign .login-sign-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
}
.login .login-sign .login-sign-container .sign-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .login-sign .login-sign-container .sign-logo img {
  max-width: 250px;
  width: 100%;
}
.login .login-sign .login-sign-container .sign-choice {
  display: flex;
  justify-content: center;
}
.login .login-sign .login-sign-container .sign-choice .sign-choice-wrapper {
  display: flex;
  gap: calc(var(--gap) / 2);
  max-width: 370px;
  width: 100%;
  padding: 5.5px;
  background-color: var(--lighterBg);
  border-radius: var(--wrapperBorderRadius);
  box-shadow: var(--whiteShadow);
  height: 50px;
}
.login .login-sign .login-sign-container .sign-choice .sign-choice-wrapper .navLink {
  width: 100%;
  border-radius: var(--buttonBorderRadius);
  background-color: var(--lighterBg);
  outline: 0;
  border: 1px solid transparent;
  color: var(--darkerTextColor);
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 150ms linear;
}
.login .login-sign .login-sign-container .sign-choice .sign-choice-wrapper .active {
  color: var(--lighterBg);
  border-radius: var(--buttonBorderRadius);
  background-color: var(--btnBgBlue);
  transition: all 150ms linear;
}
.login .login-sign .sign-mail-wrapper {
  width: 100%;
}
.login .login-sign .sign-mail-wrapper .sign-mail {
  display: grid;
  gap: var(--gap4x);
  justify-items: end;
  max-width: 554px;
  width: 100%;
  margin: 0 auto;
}
.login .login-sign .sign-mail-wrapper .sign-mail .sign-mail-links {
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  align-items: center;
  margin-top: var(--gap);
  height: 40px;
}
.login .login-sign .sign-mail-wrapper .sign-mail .sign-mail-links.mail-active {
  justify-content: flex-end;
}
.login .login-sign .sign-mail-wrapper .sign-mail .sign-mail-links .sign-mail-confirmation {
  text-transform: none;
  font-size: 14px;
  border-radius: var(--wrapperBorderRadius);
}
.login .login-sign .sign-mail-wrapper .sign-mail .sign-mail-links .sign-mail-forgot {
  text-align: end;
  color: var(--blueBrand);
  transition: all 150ms linear;
}
.login .login-sign .sign-mail-wrapper .sign-mail .sign-mail-links .sign-mail-forgot:hover {
  color: var(--darkerTextColor);
  transition: all 150ms linear;
}
.login .login-sign .sign-mail-wrapper .sign-mail .others-log {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: var(--blueBrand);
  border-radius: var(--inputBorderRadius);
  border: 1px solid transparent;
  color: var(--lighterBg);
  font-weight: 700;
  cursor: pointer;
}
.login .login-sign .register-wrapper {
  width: 100%;
}
.login .login-sign .register-wrapper .register {
  display: grid;
  gap: var(--gap2x);
  max-width: 554px;
  width: 100%;
  margin: 0 auto;
}
.login .login-sign .register-wrapper .register .others-log {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: var(--blueBrand);
  border-radius: var(--inputBorderRadius);
  border: 1px solid transparent;
  color: var(--lighterBg);
  font-weight: 700;
  cursor: pointer;
}
.login .login-sign .register-wrapper .register .others-log:disabled {
  opacity: 0.2;
  cursor: auto;
}
.login .login-sign .sign-others {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);
  max-width: 488px;
  width: 100%;
  margin: 0 auto;
}
.login .login-sign .sign-others .others-divider {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.login .login-sign .sign-others .others-divider .others-divider-line {
  background-color: var(--lighterTextColor);
  height: 1px;
  width: 100%;
}
.login .login-sign .sign-others .others-divider .others-divider-text {
  color: var(--lighterTextColor);
  font-size: 12px;
  min-width: 90px;
}
.login .login-sign .sign-others .others-sign-in {
  border: 1px solid var(--lighterTextColor);
  border-radius: var(--inputBorderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  gap: var(--gap2x);
  background-color: var(--lighterBg);
  transition: background-color 150ms linear;
  cursor: pointer;
}
.login .login-sign .sign-others .others-sign-in div {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap);
}
.login .login-sign .sign-others .others-sign-in:hover {
  background-color: var(--background);
  transition: background-color 150ms linear;
}
.login .login-sign .sign-others .others-sign-in span {
  color: var(--darkerTextColor);
  font-weight: 700;
}

@media (max-width: 1024px) {
  .login {
    flex-direction: column-reverse;
  }
  .login .login-sign {
    padding: 20px;
  }
}/*# sourceMappingURL=Login.css.map */