.navbar-drawer .MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}
.navbar-drawer .navbar-drawer-header .navbar-logo {
  height: 50px;
  margin-left: 56px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: all 150ms linear;
}
.navbar-drawer .navbar-drawer-header .navbar-logo-hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all 150ms linear;
}
.navbar-drawer .navbar-logo-wrapper {
  display: grid;
  min-height: 40px;
  margin-bottom: 15px;
}
.navbar-drawer .navbar-logo-wrapper .navbar-mini-logo {
  height: 40px;
  width: 64px;
  padding: 0px 15px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: all 150ms linear;
}
.navbar-drawer .navbar-logo-wrapper .navbar-mini-logo-hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all 150ms linear;
}
.navbar-drawer .navbar-profile-details {
  margin: 10px;
  padding: 10px;
  background: var(--background);
  box-shadow: 0px 0px 2px;
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.navbar-drawer .navbar-profile-details .navbar-expand {
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.4117647059);
}
.navbar-drawer .navbar-profile-details .navbar-profile-info {
  display: grid;
  gap: 4px;
}
.navbar-drawer .navbar-profile-details .navbar-profile-info .profile-text-header {
  font-size: 14px;
}
.navbar-drawer .navbar-profile-details .navbar-profile-info .profile-text-email {
  font-size: 10px;
}
.navbar-drawer .navbar-profile-menu .navbar-profile-menu-items {
  font-size: 12px;
}
.navbar-drawer .navbar-links {
  display: grid;
  align-content: space-between;
  height: 100%;
  margin-top: 10px;
  gap: var(--gap3x);
}
.navbar-drawer .navbar-links .active::before {
  content: "";
  width: 5px;
  height: 44px;
  background-color: var(--navActiveLink);
  border-radius: 0 5px 5px 0;
}
.navbar-drawer .navbar-links ul {
  padding: 0;
}
.navbar-drawer .navbar-links ul li .list-item-link .list-item-text span {
  font-size: 14px;
}
.navbar-drawer .profile-details-accordion {
  transition: all 150ms linear;
  box-shadow: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.navbar-drawer .profile-details-accordion::before {
  height: 0 !important;
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary {
  min-height: 45px;
  max-height: 60px;
  height: 60px;
  display: block;
  padding: 10px 0px;
  margin: 0;
  transition: all 150ms linear;
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary:hover {
  box-shadow: var(--shadow);
  transition: all 150ms linear;
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  gap: var(--gap);
  margin: 0;
  padding: 10px 0;
  background-color: var(--background);
  border-radius: var(--wrapperBorderRadius);
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary .profile-headline-container {
  display: flex;
  transition: all 150ms linear;
  width: 100%;
  justify-content: space-around;
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary .profile-headline-container .profile-headline-wrapper .profile-headline-header {
  font-size: 13px;
}
.navbar-drawer .profile-details-accordion .profile-details-accordion-summary .profile-headline-container .profile-headline-wrapper .profile-headline-text {
  font-size: 11px;
}

@keyframes example {
  from {
    background-color: red;
  }
  to {
    background-color: yellow;
  }
}
@keyframes circularRotate {
  0% {
    transform: rotate(0deg);
    /* Fix IE11 wobbly */
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-logo {
  width: 70px;
  height: 70px;
  animation: infinite;
  animation-name: circularRotate;
  animation-duration: 2000ms;
}/*# sourceMappingURL=Navbar.css.map */