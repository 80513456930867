.support-tazmin-modal {
  display: flex;
  flex-direction: column;
  gap: var(--gap3x);

  .tazmin-triple {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--gap2x);

    &.disabled {
      opacity: 0.7;
    }
  }

  .tazmin-headline {
    display: flex;
    align-items: center;
    gap: var(--gap2x);
    flex-wrap: wrap;

    h5 {
      color: var(--priceText);
    }
  }

  .tazmin-navlun {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--gap3x);

    h5 {
      color: var(--priceText);
    }

    .tazmin-triple {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: var(--gap2x);

      &.disabled {
        opacity: 0.7;
      }
    }
  }
}
