.my-wallet-arrangement-bill-modal {
  background-color: var(--lighterBg);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  border-radius: var(--wrapperBorderRadius);
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);

  .my-wallet-arrangement-bill-modal-headline {
    font-size: 16px;
  }

  .my-wallet-arrangement-bill-modal-info {
    background-color: var(--shipmentRed);
    display: flex;
    gap: var(--gap);
    padding: 5px;
    border-radius: var(--wrapperBorderRadius);

    svg {
      color: var(--lighterBg);
    }

    p {
      font-size: 12px;
      color: var(--lighterBg);
    }
  }

  .my-wallet-arrangement-bill-modal-inputs {
    display: flex;
    gap: var(--gap);
    justify-content: space-between;
  }

  .bakiye-table-row {
    th {
      padding: 5px;
    }

    td {
      padding: 5px;
    }
  }

  @media (max-width: 550px) {
    .my-wallet-arrangement-bill-modal-inputs {
      display: grid;
      justify-content: initial;
    }
  }
}

.borderless-tablerow {
  td {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.borderless-tablerow-reject {
  &.borderless2 {
    td {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  td {
    padding-top: 10px;
  }
}

.iyzi-button {
  background-color: var(--pastelBlue) !important;
  text-transform: none !important;
  color: var(--blueBrand) !important;
  transition: all 150ms linear !important;
  border-radius: var(--wrapperBorderRadius) !important;
  box-shadow: var(--shadow);

  svg {
    color: var(--blueBrand);
    transition: all 150ms linear;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--blueBrand) !important;
    color: var(--pastelBlue) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--pastelBlue);
      transition: all 150ms linear;
    }
  }
}

.iyzi-button-blue {
  background-color: var(--btnBgBlue) !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  transition: all 150ms linear !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  gap: 5px;

  svg {
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--lighterBg) !important;
    color: var(--btnBgBlue) !important;
    border: 1px solid var(--btnBgBlue) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--btnBgBlue) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-bg {
  background-color: var(--lighterBg) !important;
  text-transform: none !important;
  color: var(--btnBgBlue) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;

  svg {
    color: var(--btnBgBlue) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--btnBgBlue) !important;
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--lighterBg) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-sea {
  background-color: var(--btnBgBlue) !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;

  svg {
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    border: 1px solid var(--btnBgBlue) !important;
    background-color: var(--lighterBg) !important;
    color: var(--btnBgBlue) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--btnBgBlue) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-white {
  background-color: var(--lighterBg) !important;
  text-transform: none !important;
  color: var(--btnBgBlue) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--btnBgBlue) !important;
  transition: all 150ms linear !important;

  svg {
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--btnBgBlue) !important;
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;
    border: 1px solid var(--lighterBg) !important;

    svg {
      color: var(--lighterBg) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-red {
  background-color: var(--shipmentRed) !important;
  text-transform: none !important;
  color: var(--pastelRed) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--shipmentRed) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);

  svg {
    color: var(--pastelRed) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--pastelRed) !important;
    color: var(--shipmentRed) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--shipmentRed) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-green {
  background-color: var(--pastelGreen) !important;
  text-transform: none !important;
  color: var(--shipmentGreen) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--pastelGreen) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);

  svg {
    color: var(--shipmentGreen) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--shipmentGreen) !important;
    color: var(--pastelGreen) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--pastelGreen) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-yellow {
  background-color: var(--shipmentYellow) !important;
  text-transform: none !important;
  color: var(--pastelYellow) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid var(--shipmentYellow) !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);

  svg {
    color: var(--pastelYellow) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--pastelYellow) !important;
    color: var(--shipmentYellow) !important;
    transition: all 150ms linear !important;

    svg {
      color: var(--shipmentYellow) !important;
      transition: all 150ms linear !important;
    }
  }
}

.iyzi-button-gray {
  background-color: #7c7c7a !important;
  text-transform: none !important;
  color: var(--lighterBg) !important;
  border-radius: var(--wrapperBorderRadius) !important;
  border: 1px solid #7c7c7a !important;
  transition: all 150ms linear !important;
  box-shadow: var(--shadow);

  svg {
    color: var(--lighterBg) !important;
    transition: all 150ms linear !important;
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    background-color: var(--lighterBg) !important;
    color: #7c7c7a !important;
    transition: all 150ms linear !important;

    svg {
      color: #7c7c7a !important;
      transition: all 150ms linear !important;
    }
  }
}

.main-link-wrapper {
  cursor: pointer !important;

  &:hover {
    .main-link {
      span {
        background: var(--pastelBlue);
        border-radius: var(--wrapperBorderRadius);
      }
    }
  }

  .main-link {
    span {
      font-size: 12px !important;
      width: fit-content;
      padding: 3px 10px;
    }
  }
}

.reactpicker-wrapper {
  display: grid;
  gap: 5px;

  label {
    font-size: 13px;
    color: var(--priceText);
  }

  input {
    border-radius: var(--wrapperBorderRadius);
    outline: 0;
    border: 1px solid var(--inputBorderColor);
    height: 30px;
    padding: 0 10px;
    max-width: 130px;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .react-datepicker__calendar-icon {
        position: absolute;
        z-index: 2;
        fill: var(--priceText);
        top: 8px;
        left: 6px;
        padding: 0;
      }
    }
  }
}

.status {
  padding: 5px 10px;
  border-radius: var(--wrapperBorderRadius);
  text-align: center;
  font-weight: 700;
  font-size: inherit;

  &.status0 {
    background-color: var(--pastelYellow);
    color: var(--shipmentYellow);
  }

  &.status1 {
    background-color: var(--pastelGreen);
    color: var(--shipmentGreen);
  }

  &.status2 {
    background-color: var(--pastelRed);
    color: var(--shipmentRed);
  }

  &.tanzim0 {
    background-color: var(--pastelYellow);
    color: var(--shipmentYellow);
  }

  &.tanzim1 {
    background-color: var(--pastelYellow);
    color: var(--shipmentYellow);
  }

  &.tanzim2 {
    background-color: var(--pastelYellow);
    color: var(--shipmentYellow);
  }

  &.tanzim3 {
    background-color: var(--pastelGreen);
    color: var(--shipmentGreen);
  }

  &.tanzim4 {
    background-color: var(--pastelRed);
    color: var(--shipmentRed);
  }
}

.iyzi-export {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .iyzi-date-range {
    display: flex;

    input {
      padding: 5px 10px;
      border-radius: var(--wrapperBorderRadius);
      border: 1px solid var(--inputBorderColor);
      margin: 0 var(--gap);
    }
  }
}

.new-address-modal-wrapper {
  .react-tel-input {
    display: flex;
    align-self: baseline;
  }
}

.integration-list-item {
  grid-template-columns: repeat(auto-fit, minmax(317px, 1fr));
}

@media (max-width: 500px) {
  .integration-list-item {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
