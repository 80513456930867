.courier-delivered-admin {
  max-width: 1100px;
  width: 100%;
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--inputBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
}
@media screen and (max-width: 768px) {
  .courier-delivered-admin {
    width: 90%;
  }
}
.courier-delivered-admin .courier-delivered-drag-drop {
  display: grid;
  grid-auto-flow: column;
  gap: var(--gap2x);
}
@media screen and (max-width: 768px) {
  .courier-delivered-admin .courier-delivered-drag-drop {
    grid-auto-flow: row;
  }
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items-headline {
  color: var(--priceText);
  font-size: 16px;
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items-text {
  color: var(--priceText);
  font-size: 13px;
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items-text .courier-items-text-code {
  color: var(--info);
  font-weight: 700;
  font-size: 13px;
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items {
  height: 100%;
  width: 360px;
  margin: auto;
}
@media screen and (max-width: 468px) {
  .courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items {
    width: 310px;
  }
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container {
  cursor: pointer;
  transition: all 150ms linear;
}
@media screen and (max-width: 468px) {
  .courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container {
    width: 90%;
  }
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container:first-child:hover {
  background-color: var(--pastelBlue);
  transition: all 150ms linear;
  border-top-left-radius: var(--inputBorderRadius);
  border-top-right-radius: var(--inputBorderRadius);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container:last-child:hover {
  background-color: var(--pastelBlue);
  transition: all 150ms linear;
  border-bottom-left-radius: var(--inputBorderRadius);
  border-bottom-right-radius: var(--inputBorderRadius);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container:hover {
  background-color: var(--pastelBlue);
  transition: all 150ms linear;
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container .courier-item {
  color: var(--priceText);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-wrapper .courier-items .courier-item-container .courier-item .courier-item-awb {
  font-weight: 700;
  color: var(--darkerTextColor);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-changer {
  display: flex;
  flex-direction: column;
  gap: var(--gap2x);
  align-self: center;
  margin-top: var(--gap6x);
}
@media screen and (max-width: 468px) {
  .courier-delivered-admin .courier-delivered-drag-drop .courier-items-changer {
    width: 90%;
  }
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-changer .items-changer {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--buttonBorderRadius);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-changer .items-changer.none {
  border: 1px solid var(--inputBorderColor);
}
.courier-delivered-admin .courier-delivered-drag-drop .courier-items-changer .items-changer.has-items {
  border: 1px solid var(--info);
  cursor: pointer;
}
.courier-delivered-admin .courier-delivered-input {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.courier-delivered-admin .courier-delivered-input .delivered-input-headline {
  color: var(--priceText);
  font-size: 13px;
}
.courier-delivered-admin .courier-delivered-input .delivered-input-headline .headline-span {
  font-weight: 700;
  color: var(--info);
  font-size: 13px;
}
.courier-delivered-admin .courier-delivered-button {
  display: flex;
  gap: var(--gap2x);
  justify-content: flex-end;
}
.courier-delivered-admin .courier-delivered-button .abort {
  background-color: var(--inputBorderColor);
  color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  text-transform: none;
  max-width: 100px;
  width: 100%;
  transition: all 150ms linear;
}
.courier-delivered-admin .courier-delivered-button .abort:hover {
  transition: all 150ms linear;
  background-color: var(--priceText);
}
.courier-delivered-admin .courier-delivered-button .send {
  background-color: var(--info);
  color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  text-transform: none;
  max-width: 100px;
  width: 100%;
  transition: all 150ms linear;
}
.courier-delivered-admin .courier-delivered-button .send:hover {
  transition: all 150ms linear;
  background-color: var(--blueBrand);
}/*# sourceMappingURL=CourierDeliveredAdmin.css.map */