.courier-abort-admin {
  background-color: var(--lighterBg);
  padding: 20px;
  border-radius: var(--wrapperBorderRadius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  outline: 0;
  display: grid;
  gap: var(--gap4x);
  max-width: 1100px;
  width: 100%;
}
.courier-abort-admin .courier-abort-admin-buttons {
  display: flex;
  justify-content: space-around;
  gap: var(--gap2x);
}
.courier-abort-admin .courier-abort-admin-buttons .abort {
  background-color: var(--inputBorderColor);
  color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  text-transform: none;
  max-width: 200px;
  width: 100%;
  transition: all 150ms linear;
}
.courier-abort-admin .courier-abort-admin-buttons .abort:hover {
  transition: all 150ms linear;
  background-color: var(--priceText);
}
.courier-abort-admin .courier-abort-admin-buttons .send {
  background-color: var(--notRed);
  color: var(--lighterBg);
  border-radius: var(--inputBorderRadius);
  text-transform: none;
  max-width: 200px;
  width: 100%;
  transition: all 150ms linear;
}
.courier-abort-admin .courier-abort-admin-buttons .send:hover {
  transition: all 150ms linear;
  background-color: var(--redBrand);
}/*# sourceMappingURL=CourierAbortAdmin.css.map */