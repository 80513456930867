.notification {
  position: sticky;
  top: 0;
  z-index: 1201;
}
.notification .drawer-arrow-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: var(--lighterBg);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1202;
}
.notification .drawer-arrow-wrapper.admin-wrapper {
  margin-bottom: 0;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .menu-arrow {
  z-index: 1201;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-left {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-left img {
  max-width: 144px;
  width: 100%;
}
@media (max-width: 500px) {
  .notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-left img {
    max-width: 80px;
  }
}
@media (max-width: 420px) {
  .notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-left img {
    display: none;
  }
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-right {
  display: flex;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-right .drawer-arrow {
  position: relative;
  z-index: 1201;
  width: 40px;
  height: 40px;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-right .drawer-settings {
  position: relative;
  z-index: 1201;
  min-width: 40px;
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.notification .drawer-arrow-wrapper .drawer-arrow-contain .drawer-right .drawer-settings:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.notification .notification-drawer .MuiDrawer-paper {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
}
.notification .notification-drawer .MuiDrawer-paper .notifications {
  margin-top: 64px;
  display: grid;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification {
  height: 90px;
  background-color: var(--notRed);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification:hover {
  background-color: var(--notRed);
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification .notification-progress {
  width: 2px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  margin-left: 20px;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification .notification-status {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 11px;
  top: 13px;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: var(--notRed);
  border-radius: 50%;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification .notification-status .status-date {
  font-size: 12px;
  color: var(--lighterBg);
  position: absolute;
  margin-left: 73px;
  font-weight: 700;
  padding-bottom: 1px;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification .notification-status svg {
  color: rgba(0, 0, 0, 0.2);
  height: 14px;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification .notification-text {
  color: var(--lighterBg);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-left: 40px;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  transition: all 150ms linear;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification button:hover {
  background: rgba(0, 0, 0, 0.2);
  transition: all 150ms linear;
}
.notification .notification-drawer .MuiDrawer-paper .notifications .notification button svg {
  color: rgba(0, 0, 0, 0.4);
}/*# sourceMappingURL=Notification.css.map */