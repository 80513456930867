.courier-registration {
  min-height: 100vh;
  background-color: var(--background);

  .courier-registration-wrapper {
    display: grid;
    width: 100%;
    gap: var(--gap2x);

    .courier-registration-container {
      margin-top: var(--gap4x);

      .courier-list {
        display: grid;
        justify-items: center;
        gap: var(--gap2x);

        .courier-list-item {
          display: flex;
          gap: var(--gap2x);
          max-width: 1300px;
          width: 100%;
          justify-content: space-between;
          background-color: var(--lighterBg);
          padding: 20px;
          border-radius: var(--wrapperBorderRadius);
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

          .courier-list-item-detail-wrapper {
            display: flex;
            justify-content: space-between;
            gap: var(--gap2x);

            .courier-list-item-detail {
              max-width: 600px;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: var(--gap2x);

              .delivery-top {
                display: flex;
                gap: var(--gap2x);

                .delivery-code {
                  color: var(--priceText);
                  font-weight: 700;
                  font-size: 13px;

                  .delivery-code-span {
                    color: var(--info);
                    font-weight: 700;
                    font-size: 13px;
                  }
                }
                .registration-date {
                  color: var(--priceText);
                  font-weight: 700;
                  font-size: 13px;
                  max-width: 250px;
                  width: 100%;

                  .registration-date-span {
                    font-weight: 400;
                    font-size: 13px;
                  }
                }
              }
              .delivery-address {
                color: var(--priceText);
                font-weight: 700;
                font-size: 13px;

                .delivery-address-span {
                  font-weight: 400;
                  font-size: 13px;
                }
              }
            }

            .courier-list-item-buttons {
              display: flex;
              flex-direction: column;
              gap: var(--gap);
              max-width: 140px;
              width: 100%;

              .courier-list-item-divider {
                margin: 0 5px;
                background-color: var(inputBorderColor);
                height: 1px;
                width: 100%;
              }

              .courier-list-item-button-wrapper {
                display: grid;
                gap: var(--gap);

                .courier-list-item-button {
                  display: flex;
                  gap: var(--gap);
                  align-items: center;
                  font-size: 13px;

                  .clck {
                    width: 24px;
                    height: 24px;
                    background-color: var(--info);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: var(--inputBorderRadius);
                    cursor: pointer;

                    &.abort {
                      transition: all 150ms linear;
                      background-color: var(--notRed);

                      &:hover {
                        transition: all 150ms linear;
                        background-color: var(--redBrand);
                      }
                    }

                    .clck-btn {
                      width: 16px;
                      height: 16px;
                    }

                    &:hover {
                      background-color: var(--blueBrand);
                    }
                  }

                  .set-btn {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }

          .courier-list-item-confirm-admin {
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: var(--gap2x);

            .delivery-status {
              font-size: 13px;
              text-align: center;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 10px;
              border-radius: 9999px;
              font-weight: 600;
              max-width: 600px;
              width: 100%;

              &.active {
                background-color: var(--pastelGreen);
                color: var(--greenBrand);
              }

              &.waiting {
                background-color: var(--pastelYellow);
                color: var(--orangeBrand);
              }

              &.closed {
                background-color: var(--pastelRed);
                color: var(--redBrand);
              }

              &.on-the-way {
                background-color: var(--pastelBlue);
                color: var(--info);
              }
            }

            .registration-abort {
              text-transform: none;
              font-size: 13px;
              background-color: var(--info);
              color: var(--lighterBg);
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: var(--redBrand);
              transition: all 150ms linear;

              &.active {
                background-color: var(--info);
                color: var(--lighterBg);

                &:hover {
                  background-color: var(--blueBrand);
                  color: var(--lighterBg);
                }
              }

              &:hover {
                background-color: red;
                transition: all 150ms linear;
              }
            }

            .delivery-code {
              color: var(--priceText);
              font-weight: 700;
              font-size: 13px;

              .delivery-code-span {
                color: var(--info);
                font-weight: 700;
                font-size: 13px;
              }
            }
          }
        }

        .courier-list-item-delivered {
          max-width: 1100px;
          width: 100%;
          background-color: var(--lighterBg);
          padding: 20px;
          border-radius: var(--wrapperBorderRadius);
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

          .delivery-status-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: var(--gap2x);

            .delivery-status {
              font-size: 13px;
              max-width: 150px;
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 10px;
              border-radius: 9999px;
              font-weight: 600;

              &.active {
                background-color: var(--pastelGreen);
                color: var(--greenBrand);
              }
            }
          }

          .courier-list-item-detail {
            display: flex;
            gap: var(--gap);

            .courier-column {
              display: flex;

              .delivery-column {
                width: 240px;
                display: flex;
                flex-direction: column;
                gap: var(--gap2x);

                .delivery-column-row {
                  color: var(--priceText);
                  font-weight: 700;
                  font-size: 13px;

                  .delivery-code-span {
                    color: var(--info);
                    font-weight: 700;
                    font-size: 13px;
                  }

                  .delivery-column-row-span {
                    font-size: 13px;
                    font-weight: 400;
                    color: var(--priceText);
                  }
                }
              }
            }

            .delivery-address {
              color: var(--priceText);
              font-weight: 700;
              font-size: 13px;

              .delivery-address-span {
                font-weight: 400;
                font-size: 13px;
              }
            }
          }

          .courier-list-item-detail-show {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: var(--gap2x);

            button {
              text-transform: none;
              background-color: var(--info);
              color: var(--lighterBg);
              transition: all 150ms linear;
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              height: 35px;
              padding: 0 10px;

              svg {
                width: 16px !important;
                height: 16px !important;
              }

              &:hover {
                background-color: var(--blueBrand);
              }
            }
          }
        }

        .courier-list-item-details {
          max-width: 1100px;
          width: 100%;
          justify-content: space-between;
          background-color: var(--lighterBg);
          padding: 20px 0;
          border-radius: var(--wrapperBorderRadius);
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

          .list-item-details {
            display: grid;
            grid-template-columns: repeat(3, minmax(150px, 1fr));
            width: 100%;
            justify-items: center;
            align-items: center;
            padding: 10px;

            .list-item-detail {
              color: var(--priceText);
              font-size: 13px;

              .list-item-detail-span {
                font-size: 13px;
                font-weight: 700;
                color: var(--info);
              }
            }

            .list-item-status {
              padding: 5px 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 9999px;
              font-size: 13px;
              width: 150px;
              font-weight: 600;

              &.active {
                color: var(--greenBrand);
                background-color: var(--pastelGreen);
              }

              &.closed {
                background-color: var(--pastelRed);
                color: var(--redBrand);
              }
            }
          }

          .list-items-details-close {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            button {
              background-color: var(--info);
              text-transform: none;
              color: var(--lighterBg);
              transition: all 150ms linear;
              height: 35px;
              width: fit-content;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                background-color: var(--blueBrand);
                transition: all 150ms linear;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .courier-registration {
    .courier-registration-wrapper {
      .courier-registration-container {
        .courier-list {
          .courier-list-item {
            .courier-list-item-detail-wrapper {
              flex-direction: column;
              .courier-list-item-detail {
                max-width: 100%;
              }

              .courier-list-item-buttons {
                flex-direction: row;
                max-width: 100%;
                justify-content: space-between;
                align-items: center;

                .courier-list-item-divider {
                  margin: 5px 0;
                  background-color: var(--inputBorderColor);
                  height: 100%;
                  width: 1px;
                }

                .courier-list-item-button-wrapper {
                  display: grid;
                  gap: var(--gap);
                }
              }
            }
          }

          .courier-list-item-delivered {
            .courier-list-item-detail {
              .courier-column {
                flex-direction: column;
                gap: var(--gap);

                .delivery-column {
                  gap: var(--gap);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .courier-registration {
    .courier-registration-wrapper {
      .courier-registration-container {
        .courier-list {
          .courier-list-item {
            gap: var(--gap);
            .courier-list-item-detail {
              max-width: 300px;
            }
            .courier-list-item-confirm-admin {
              .registration-abort {
                width: 160px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .courier-registration {
    .courier-registration-wrapper {
      .courier-registration-container {
        .courier-list {
          .courier-list-item-details {
            .list-item-details {
              grid-template-columns: repeat(1, minmax(150px, 1fr));
              gap: var(--gap);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .courier-registration {
    .courier-registration-wrapper {
      .courier-registration-container {
        .courier-list {
          .courier-list-item {
            flex-direction: column;
            .courier-list-item-confirm-admin {
              flex-direction: column;
              margin-top: 10px;

              .delivery-status {
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              }
            }
          }

          .courier-list-item-delivered {
            .courier-list-item-detail {
              flex-direction: column;

              .courier-column {
                flex-direction: column;
                gap: var(--gap);

                .delivery-column {
                  gap: var(--gap);
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .courier-registration {
    .courier-registration-wrapper {
      .courier-registration {
        .courier-list {
          .courier-list-item {
            .courier-list-item-confirm-admin {
              flex-direction: column;
              align-items: center;

              .delivery-status {
                width: 100%;
              }

              .registration-abort {
                width: 100%;
              }
            }
          }

          .courier-list-item-delivered {
            .delivery-status-wrapper {
              justify-content: inherit;

              .delivery-status {
                max-width: 100%;
              }
            }

            .courier-list-item-detail-show {
              display: flex;
              justify-content: inherit;
              width: 100%;
              margin-top: var(--gap2x);

              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
