.dropzone-wrapper {
  display: grid;
  justify-items: center;
  gap: var(--gap2x);

  .dropzone {
    display: grid;
    justify-items: center;
    gap: var(--gap2x);
    border: 1px dashed rgba(0, 0, 0, 0.23);
    border-radius: var(--wrapperBorderRadius);
    padding: var(--gap2x);
    width: 100%;
    cursor: pointer;
    transition: all 150ms linear;

    &:hover {
      background-color: var(--pastelBlue);
      border: 1px solid rgba(0, 0, 0, 0.23);
      transition: all 150ms linear;
    }

    .dropzone-area {
      width: 100%;

      .dropzone-area-text {
        display: grid;
        justify-items: center;
        gap: var(--gap2x);

        .dropzone-area-text-headline {
          font-size: 1rem;
          font-weight: 700;
        }

        .dropzone-area-text-sub {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  .dropzone-uploader-button {
    padding: 7px 30px;
    border: 1px solid var(--btnBgBlue);
    background-color: var(--btnBgBlue);
    border-radius: var(--buttonBorderRadius);
    color: var(--lighterBg);
    font-size: var(--smallTextSize);
    transition: all 150ms linear;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--blueBrand);
      background-color: var(--blueBrand);
      transition: all 150ms linear;
    }
  }
}