.file-item {
  border: 1px solid rgba(0, 0, 0, 0.38);
  min-width: 500px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  transition: all 0.3 ease;
}
.file-text-container {
  width: 90%;
}
@media only screen and (max-width: 1070px) {
  .file-item {
    min-width: 300px;
  }
}
.file-text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.file-delete-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 20px;
  margin-right: 10px;
}

.left-icon {
  width: 20px;
  margin-left: 10px;
}
.file-item.deleted {
  opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.file-item-enter {
  opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.file-item-enter-active {
  opacity: 1;
  max-height: 100px; /* Orjinal yüksekliği, burada dosya yüksekliğine uygun bir değer kullanmalısınız */
  padding: 8px;
  margin: 10px 0;
  overflow: hidden;
  transition: opacity 300ms ease, max-height 300ms ease, padding 300ms ease, margin 300ms ease;
}

.file-item-exit {
  opacity: 1;
  max-height: 100px; /* Orjinal yüksekliği, burada dosya yüksekliğine uygun bir değer kullanmalısınız */
  padding: 8px;
  margin: 10px 0;
  overflow: hidden;
}

.file-item-exit-active {
  opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: opacity 300ms ease, max-height 300ms ease, padding 300ms ease, margin 300ms ease;
}