.connected-payments {
  outline: 0;
  max-width: 1100px;
  margin: 0 auto;

  .connected-payments-headline {
    display: flex;
    align-items: center;
    font-weight: 700;
    gap: var(--gap);
    padding: 20px;
  }

  .desktop-table {
    display: table;
  }

  .mobile-table {
    display: none;
  }

  @media(max-width: 650px) {
    .desktop-table {
      display: none;
    }

    .mobile-table {
      display: table;
    }
  }
}